import { useLanguage } from '../contexts/LanguageContext';

const HeroSection = () => {
  const { t, language } = useLanguage();

  return (
    <section id="hero" className="text-center mb-16">
      <div className="flex flex-col items-center mb-16 pt-8">
        <div className="w-24 h-1 bg-gradient-to-r from-yellow-400 to-yellow-500 rounded-full mb-2"></div>
        <div className="w-16 h-1 bg-gradient-to-r from-yellow-400 to-yellow-500 rounded-full opacity-50"></div>
      </div>

      <div className="mb-12 bg-[#2A2A2A] p-8 rounded-2xl shadow-lg max-w-3xl mx-auto transform hover:scale-105 transition-transform duration-300">
        <p className="text-2xl font-semibold text-white">
          {language === 'fr' 
            ? 'Micro PC Plus deviendra bientôt'
            : 'Micro PC Plus will soon become'}
        </p>
        <div className="mt-6 relative">
          <div className="w-48 h-48 mx-auto relative">
            <svg className="absolute inset-0 w-full h-full" viewBox="0 0 100 100">
              <path
                d="M 30,0 L 70,0 L 100,30 L 100,70 L 70,100 L 30,100 L 0,70 L 0,30 Z"
                fill="none"
                stroke="#FF6B45"
                strokeWidth="3"
                className="w-full h-full"
              />
            </svg>
            <div className="absolute inset-0 flex flex-col items-center justify-center">
              <p className="text-4xl font-bold text-white tracking-wider">
                TECH<span className="text-[#FF6B45] font-normal">-</span>NICK
              </p>
              <p className="text-lg text-[#FF6B45] tracking-wide mt-1 font-medium">
                INFORMATIQUE
              </p>
            </div>
          </div>
        </div>
      </div>

      <h2 className="text-5xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-yellow-600 to-yellow-400">
        {t.heroTitle}
      </h2>
      <p className="text-xl mb-8 text-gray-700">{t.heroSubtitle}</p>
      <a
        href="#contact"
        className="bg-gradient-to-r from-yellow-400 to-yellow-500 text-black px-8 py-3 rounded-full hover:from-yellow-500 hover:to-yellow-600 transition-all duration-300 shadow-lg hover:shadow-xl transform hover:-translate-y-1"
      >
        {t.contactUs}
      </a>
    </section>
  );
};

export default HeroSection;