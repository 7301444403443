import React from 'react';
import { BuildReasonProps } from '../types';

const BuildReason: React.FC<BuildReasonProps> = ({ Icon, title, description }) => {
  return (
    <div className="flex items-start space-x-4 mb-6 last:mb-0">
      <Icon className="w-12 h-12 text-orange-500 flex-shrink-0" />
      <div>
        <h3 className="text-xl font-semibold mb-2 text-gray-900">{title}</h3>
        <p className="text-gray-700">{description}</p>
      </div>
    </div>
  );
}

export default BuildReason;