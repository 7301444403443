import React from 'react';
import { BuildFeatureProps } from '../types';

const BuildFeature: React.FC<BuildFeatureProps> = ({ Icon, title, description }) => {
  return (
    <div className="bg-gradient-to-br from-orange-50 to-orange-100 p-8 rounded-2xl shadow-lg transform transition-all duration-300 hover:-translate-y-1 hover:shadow-xl">
      <div className="flex flex-col h-full">
        <Icon className="w-12 h-12 text-orange-500 mb-4 flex-shrink-0" />
        <h3 className="text-xl font-semibold mb-2 text-gray-900">{title}</h3>
        <p className="text-gray-700 flex-grow">{description}</p>
      </div>
    </div>
  );
}

export default BuildFeature;