import { useLanguage } from '../contexts/LanguageContext';

const LocationMap = () => {
  const { t } = useLanguage();

  return (
    <div className="flex flex-col rounded-2xl overflow-hidden shadow-lg">
      <div className="flex-grow">
        <iframe
          title="Location Map"
          width="100%"
          height="300"
          frameBorder="0"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          className="border-0"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2794.3776795087883!2d-73.5220899!3d45.5784799!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc91e9d4fb9f841%3A0x6c006a6c8ad5fa8e!2s2347%20Rue%20des%20Ormeaux%2C%20Montr%C3%A9al%2C%20QC%20H1L%204X2!5e0!3m2!1sen!2sca!4v1629890000000!5m2!1sen!2sca"
          allowFullScreen
        />
      </div>
      <div className="bg-gradient-to-br from-orange-50 to-orange-100 p-4 text-center">
        <a
          href="https://www.google.com/maps/dir/?api=1&destination=2347+rue+des+ormeaux+Montreal+QC+H1L+4X2"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-gradient-to-r from-yellow-400 to-yellow-500 text-black px-6 py-2 rounded-full hover:from-yellow-500 hover:to-yellow-600 transition-all duration-300 inline-block shadow-md hover:shadow-lg transform hover:-translate-y-1"
        >
          {t.getDirections}
        </a>
      </div>
    </div>
  );
};

export default LocationMap;