import React from 'react';
import { ServiceCardProps } from '../types';

const ServiceCard: React.FC<ServiceCardProps> = ({ Icon, title, description }) => {
  return (
    <div className="bg-gradient-to-br from-orange-50 to-orange-100 p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300">
      <div className="flex flex-col h-full">
        <Icon className="w-12 h-12 text-orange-500 mb-4 flex-shrink-0" />
        <h3 className="text-2xl font-semibold mb-4 text-gray-900">{title}</h3>
        <p className="text-gray-700 leading-relaxed flex-grow">{description}</p>
      </div>
    </div>
  );
}

export default ServiceCard;